import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl"
import withStyles from "@material-ui/core/styles/withStyles"
import Grid from "@material-ui/core/Grid"

const styles = () => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width: '100%',
    },
});


class DeleteEntityDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            entityName: undefined
        }
    }

    handleClose(action) {
        if (action === "cancel") {
            this.props.onClose(null)
        } else {
            const entity = this.state.entity

            if (!this.state.entityName) {
                console.warn("Fill all required fields plz", entity)
                return
            }

            this.props.onClose(this.state.entityName)
        }
    }

    onNameChange(name) {
        this.setState({
            entityName: name
        })
    }

    render() {
        const {classes} = this.props
        const {entityName} = this.state

        return (
            <Dialog
                open={true}
                fullWidth={true}
                maxWidth="md"
                onClose={() => this.props.onClose()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle
                    id="form-dialog-title">Deleting entity</DialogTitle>
                <DialogContent>

                    <Grid container className={classes.root} spacing={16}>
                        <Grid item xs={12}>
                            <FormControl required className={classes.formControl}>
                                <TextField
                                    required
                                    id="name"
                                    label="Name"
                                    value={entityName}
                                    onChange={(event) => this.onNameChange(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleClose("cancel")}>
                        Cancel
                    </Button>
                    <Button onClick={() => this.handleClose()} color="secondary" variant="contained">
                        Delete forever
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(DeleteEntityDialog)
