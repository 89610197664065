import React, { Component } from 'react';
import { Link } from "react-router-dom"
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import getUrlParams from "./UrlParams"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import withStyles from "@material-ui/core/styles/withStyles"


const styles = theme => ({
    root: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        float: 'left',
        margin: "20px"
    },
    paper: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    nodeEnv: {
        float: "right"
    }
})

class Header extends Component {
    render() {
        const {classes} = this.props
        const {partnerName, entityName} = getUrlParams(this.props.match)

        let secondBreadLevel = ""
        let thirdBreadLevel = ""

        if (partnerName) {
            secondBreadLevel = <>
                <Link color="textPrimary" to={`/partner/${partnerName}`}>{partnerName}</Link>
            </>
        }

        if (entityName) {
            thirdBreadLevel = <>
                <Typography color="textPrimary">{entityName}</Typography>
                </>
        }

        return <div>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Breadcrumbs aria-label="Breadcrumb">
                        <Link color="inherit" to="/">
                            Accounts
                        </Link>
                        {secondBreadLevel}
                        {thirdBreadLevel}
                    </Breadcrumbs>
                </Paper>
            </div>
            <div className={classes.nodeEnv}>
                <Paper className={classes.paper}>
                    {process.env.NODE_ENV}
                </Paper>
            </div>
            <div>
                {this.props.children}
            </div>
        </div>
    }
}

export default withStyles(styles)(Header)
