import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles"
import getPublisherList from "../PublisherListData"
import { PublisherLang } from "../dataTypeList"
import Grid from "@material-ui/core/Grid"
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import AutoComplete from "../customComponent/AutoComplete"


const styles = () => ({

    formControl: {
        width: '100%',
    },
})

class DialogEntityPublisher extends Component {

    constructor(props) {
        super(props)

        this.state = {
            autoComplete: props.autoComplete,
            publisherList: [],
            selectedPublisher: props.value ? props.value: {
                name: "",
                url: "",
                lang: "en"
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.autoComplete !== this.props.autoComplete) {
            this.setState({
                autoComplete: nextProps.autoComplete
            })
        }
    }

    componentDidMount() {
        getPublisherList().then(publishers => {
            this.setState({
                publisherList: publishers
            })
        })
    }


    onPublisherNameChange(value) {
        const newSelectedPublisher = {
            ...this.state.selectedPublisher,
            name: value
        }
        this.setState({
            selectedPublisher: newSelectedPublisher
        })
        this.props.onChange(newSelectedPublisher)
    }

    onPublisherUrlChange(value) {
        const newSelectedPublisher = {
            ...this.state.selectedPublisher,
            url: value
        }
        this.setState({
            selectedPublisher: newSelectedPublisher
        })
        this.props.onChange(newSelectedPublisher)
    }

    onPublisherLangChange(value) {
        const newSelectedPublisher = {
            ...this.state.selectedPublisher,
            lang: value
        }
        this.setState({
            selectedPublisher: newSelectedPublisher
        })
        this.props.onChange(newSelectedPublisher)
    }

    onAutoCompleteChange(publisher) {
        this.setState({
            selectedPublisher: publisher
        })
        this.props.onChange(publisher)
    }

    render() {
        const {classes} = this.props

        if (this.state.autoComplete) {
            return <AutoComplete
                placeholder="Publisher Name"
                value={this.state.selectedPublisher}
                onChange={value => this.onAutoCompleteChange(value)}
                suggestions={this.state.publisherList.map(suggestion => ({
                    value: suggestion,
                    label: suggestion.name + "\n" + suggestion.url.substring(suggestion.url.lastIndexOf('/') + 1),
                }))}/>
        }

        return <Grid container spacing={16}>
            <Grid item xs={8}>
                <FormControl required className={classes.formControl}>
                    <TextField
                        required
                        id="publishername"
                        label="Publisher Name"
                        value={this.state.selectedPublisher.name}
                        onChange={(event) => this.onPublisherNameChange(event.target.value)}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl required className={classes.formControl}>
                    <FormControl required className={classes.formControl}>
                        <InputLabel htmlFor="publisherlang">Publisher Lang</InputLabel>
                        <Select
                            value={this.state.selectedPublisher.lang}
                            onChange={(event) => this.onPublisherLangChange(event.target.value)}
                            name="Publisher Lang"
                            inputProps={{
                                id: 'publisherlang',
                            }}
                        >
                            {PublisherLang.map(lang => <MenuItem value={lang} key={lang}>{lang}</MenuItem>)}
                        </Select>
                    </FormControl>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl required className={classes.formControl}>
                    <TextField
                        required
                        id="publisherurl"
                        label="Publisher Url"
                        helperText="File generated by the inApp team"
                        value={this.state.selectedPublisher.url}
                        onChange={(event) => this.onPublisherUrlChange(event.target.value)}
                    />
                </FormControl>

            </Grid>
        </Grid>
    }
}

export default withStyles(styles)(DialogEntityPublisher)
