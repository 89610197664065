import { fetch } from 'whatwg-fetch'

let publisherList = []

export default function getPublisherList() {
    if (publisherList.length > 0) {
        return Promise.resolve().then(() => publisherList)
    }

    return fetch('https://us-central1-firebase-teads-showcase.cloudfunctions.net/export_publisher')
        .then(response => {
            return response.json()
        }).then(json => {

            publisherList = json.filter(item => item)

            return publisherList
        })
}