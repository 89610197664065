import React from 'react'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'

const autoCompleteHeight = 110

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: autoCompleteHeight + 50,
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        height: '100px',
        alignItems: 'center',
        overflow: 'hidden',
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 200,
        marginTop: theme.spacing.unit,
        height: autoCompleteHeight,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
})

function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    )
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    )
}

const components = {
    Control,
    Menu,
}

class AutoComplete extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            label: props.value ? props.value.name : null,
            value: props.value ? props.value : null
        }
    }

    componentDidMount() {
        this.setState({
            ...this.state
        })
    }

    handleChange = value => {
        this.setState({
            value: value.value,
        })
        this.props.onChange(value.value)
    }

    render() {
        const {classes, theme, suggestions, placeholder} = this.props

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
            menuList: base => ({
                ...base,
                height: autoCompleteHeight
            })
        }

        return (
            <div className={classes.root}>
                <Select
                    required
                    classes={classes}
                    styles={selectStyles}
                    options={suggestions}
                    components={components}
                    value={ this.state.value.name && {
                        label: this.state.value.name
                    }}
                    onChange={value => this.handleChange(value)}
                    placeholder={placeholder}
                />
            </div>
        )
    }
}

export default withStyles(styles, {withTheme: true})(AutoComplete)