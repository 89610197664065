import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
    close: {
        padding: theme.spacing.unit / 2,
    },
})

class NotificationSnackbar extends Component {
    render() {
        const { error, success, classes } = this.props
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={true}
                autoHideDuration={6000}
                onClose={this.props.onClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{success ? success: error }</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.props.onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        )
    }
}

export default withStyles(styles)(NotificationSnackbar)
