export const MediaType = ['vpaidOffline', 'video']
export const type = ['landscape',
    'vertical',
    'square',
    'parallax',
    '360',
    'swing_landscape',
    'swing_square',
    'swing_vertical',
    'scroller',
    'scroller_square',
    'carousel',
    'slideshow',
    'canvas',
    'flow',
    'flow_square',
    'dragndrop']

export const AdTypeMapping = [
    {
        adType: "inRead Landscape",
        type: "landscape"
    },
    {
        adType: "inRead Square",
        type: "square"
    },
    {
        adType: "inRead Vertical",
        type: "vertical"
    },
    {
        adType: "inRead 360",
        type: "360",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Scroller",
        type: "scroller",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Scroller Square",
        type: "scroller_square",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Swing Landscape",
        type: "swing_landscape",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Swing Square",
        type: "swing_square",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Swing Vertical",
        type: "swing_vertical",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Carousel",
        type: "carousel",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Slideshow",
        type: "slideshow",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Canvas",
        type: "canvas",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Flow",
        type: "flow",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Flow Square",
        type: "flow_square",
        mediaTypeForced: "vpaidOffline"
    },
    {
        adType: "inRead Drag & Drop",
        type: "dragndrop",
        mediaTypeForced: "vpaidOffline"
    }
]

export const PublisherLang = ['en', 'de', 'es', 'fr', 'it', 'ja', 'pt', 'zh']
