import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl"
import withStyles from "@material-ui/core/styles/withStyles"
import Grid from "@material-ui/core/Grid"

const styles = () => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width: '100%',
    },
});


class NewEntityDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            entity: {
                data: {
                    baseline: "",
                    description: "",
                    version: "1",
                    ads: [],
                    publishers: [],
                    settings: [],
                    force_update: 1111,
                },
                name: "",
                domainName: props.domain
            }
        }
    }

    onNameChange(value) {
        this.setState({
            entity: {
                ...this.state.entity,
                name: value
            }
        })
    }

    onBaselineChange(value) {
        this.setState({
            entity: {
                ...this.state.entity,
                data: {
                    ...this.state.entity.data,
                    baseline: value
                }
            }
        })
    }

    onDescriptionChange(value) {
        this.setState({
            entity: {
                ...this.state.entity,
                data: {
                    ...this.state.entity.data,
                    description: value
                }
            }
        })
    }

    onLogoChange(value) {
        let logo = value.replace('data:image/png;base64,', '').trim()

        this.setState({
            entity: {
                ...this.state.entity,
                data: {
                    ...this.state.entity.data,
                    logo: logo
                }
            }
        })
    }

    handleClose(action) {
        if (action === "cancel") {
            this.props.onClose(null)
        } else {
            const entity = this.state.entity

            if (!entity.name || !entity.domainName
                || !entity.data.baseline || !entity.data.logo || !entity.data.version) {
                console.warn("Fill all required fields plz", entity)
                return
            }

            this.props.onClose(this.state.entity)
        }
    }

    render() {
        const {mode, classes} = this.props
        const {entity} = this.state

        return (
            <Dialog
                open={true}
                fullWidth={true}
                maxWidth="md"
                onClose={() => this.props.onClose()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle
                    id="form-dialog-title">{mode === "edit" ? `Editing entity #${entity.name}` : "Adding a new entity"}</DialogTitle>
                <DialogContent>

                    <Grid container className={classes.root} spacing={16}>
                        <Grid item xs={8}>
                            <FormControl required className={classes.formControl}>
                                <TextField
                                    required
                                    id="name"
                                    label="Name"
                                    value={entity.name}
                                    onChange={(event) => this.onNameChange(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl required className={classes.formControl}>
                                <TextField
                                    required
                                    readOnly
                                    disabled
                                    id="domainName"
                                    label="domainName"
                                    value={entity.domainName}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl required className={classes.formControl}>
                                <TextField
                                    required
                                    id="baseline"
                                    label="baseline"
                                    value={entity.data.baseline}
                                    onChange={(event) => this.onBaselineChange(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required className={classes.formControl}>
                                <TextField
                                    required
                                    id="description"
                                    label="description"
                                    value={entity.data.description}
                                    onChange={(event) => this.onDescriptionChange(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required className={classes.formControl}>
                                <TextField
                                    required
                                    multiline
                                    rowsMax="10"
                                    id="logo"
                                    label="logo in base64 from png"
                                    value={entity.data.logo}
                                    onChange={(event) => this.onLogoChange(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleClose("cancel")}>
                        Cancel
                    </Button>
                    <Button onClick={() => this.handleClose()} color="primary">
                        {mode === "edit" ? `Save` : "Add Entity"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(NewEntityDialog)