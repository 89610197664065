import React, { Component } from 'react';
import RequireAuth from "./RequireAuth"
import './firebase/firebase'
import PartnerList from "./PartnerList"
import { Route, Switch } from "react-router-dom"
import EntityList from "./entity/EntityList"
import Entity from "./entity/Entity"
import Header from "./Header"

class App extends Component {

    render() {
        return (
            <RequireAuth>
                <Switch>
                    <Route exact path="/" render={props =>
                        <Header  {...props}>
                            <PartnerList {...props}/>
                        </Header>}/>
                    <Route exact path="/partner/:partnerName" render={props =>
                        <Header  {...props}>
                            <EntityList {...props}/>
                        </Header>}/>
                    <Route exact path="/partner/:partnerName/entity/:entityName" render={props =>
                        <Header  {...props}>
                            <Entity {...props}/>
                        </Header>}/>
                </Switch>
            </RequireAuth>
        );
    }
}

export default App;
