import React, { Component } from 'react'
import { database } from "../firebase/firebase"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import { mergeAdsPublishersSettings, unmergeAds } from "./MergeAdsPublishersSettings"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Table from "@material-ui/core/Table"
import Button from "@material-ui/core/Button"
import EditIcon from '@material-ui/icons/Edit'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import PublishIcon from '@material-ui/icons/Publish'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import EntityEditAddDialog from "./EntityEditAddDialog"
import NotificationSnackbar from "../customComponent/NotificationSnackbar"
import getUrlParams from "../UrlParams"

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    actions: {
        float: "right",

        margin: "10px"
    },
    paper: {
        padding: '15px; ',
        margin: '15px'
    },
    table: {
        width: '100%'
    },
    button: {
        margin: theme.spacing.unit,
    },
    smallButton: {
        margin: theme.spacing.unit,
        minWidth: 32,
        padding: 5
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
});

class Entity extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mergedSettings: [],
            addingDemo: false,
            hasPendingChange: false
        }
    }

    componentWillMount() {
        const {partnerName, entityName} = getUrlParams(this.props.match)
        this.setState({
            selectedPartner: partnerName,
            selectedEntity: entityName,
        })

        database
            .ref(`v6/partners/${partnerName}/entities/${entityName}/data/`)
            .on('value', (snapshot) => {
                const items = {}
                snapshot.forEach(function (childSnapshot) {
                    items[childSnapshot.key] = childSnapshot.val()
                });
                this.setState({
                    mergedSettings: mergeAdsPublishersSettings(items),
                    data: items
                })
            }, error => {
                console.error(error)
            })

    }

    onSettingClicked(mergedSetting) {
        this.setState({
            editingItem: mergedSetting
        })
    }

    onDialogClosed(mergedSetting) {
        this.setState({
            editingItem: undefined,
            addingDemo: false
        })

        if (!mergedSetting) {
            return
        }

        const stateMergedSettings = this.state.mergedSettings

        if (mergedSetting.index === undefined) {
            mergedSetting.index = stateMergedSettings.length
        }

        stateMergedSettings[mergedSetting.index] = mergedSetting

        this.setState({
            mergedSettings: stateMergedSettings,
            hasPendingChange: true
        })
    }

    onPublishClicked() {
        const {partnerName, entityName} = getUrlParams(this.props.match)
        const {ads, publishers, settings} = unmergeAds(this.state.mergedSettings)

        if (!partnerName) {
            alert('No partner name. 🤯')
            return
        }
        if (!entityName) {
            alert('No entity name. 🤯')
            return
        }

        const update = {
            settings: settings,
            ads: ads,
            publishers: publishers
        }

        database
            .ref(`v6/partners/${partnerName}/entities/${entityName}/data/`)
            .update(update)
            .then(() => {
                this.setState({
                    successMessage: "Settings saved",
                    hasPendingChange: false
                })
            })
            .catch(error => {
                console.error("Failed to save settings", error)
                this.setState({
                    errorMessage: "Failed to save settings, error:" + JSON.stringify(error)
                })
            })
    }

    onIncreaseClicked() {
        if (this.state.hasPendingChange && !window.confirm("You have unpublished changed, increasing the version will erase those changes.")) {
            return
        }
        const {partnerName, entityName} = getUrlParams(this.props.match)

        const update = {}
        update['version'] = parseInt(this.state.data.version) + 1

        database
            .ref(`v6/partners/${partnerName}/entities/${entityName}/data/`)
            .update(update)
            .then(() => {
                this.setState({
                    successMessage: "Version increased to " + update['version']
                })
            })
            .catch(error => {
                console.error("Failed to increment version", error)
                this.setState({
                    errorMessage: "Failed to increment version, error:" + JSON.stringify(error)
                })
            })
    }

    onNewDemoClick() {
        this.setState({
            addingDemo: true
        })
    }

    moveDemo(setting, moveRange) {
        let mergedSettings = this.state.mergedSettings
        const originalIndexPos = setting.index

        let targetIndexPos
        if(moveRange > 0) {
            targetIndexPos = (originalIndexPos + moveRange) < mergedSettings.length ? setting.index + moveRange : mergedSettings.length -1
        } else {
            targetIndexPos = originalIndexPos > 0 ? setting.index + moveRange : 0
        }

        if(targetIndexPos === originalIndexPos) {
            // Target position is outside of range (either -1 or outside of array length
            return
        }

        // Move indexes
        mergedSettings[targetIndexPos].index = originalIndexPos
        mergedSettings[originalIndexPos].index = targetIndexPos

        // Swap items in the array
        const originalSetting = mergedSettings[originalIndexPos]
        mergedSettings[originalIndexPos] = mergedSettings[targetIndexPos]
        mergedSettings[targetIndexPos] = originalSetting

        this.setState({
            mergedSettings: mergedSettings
        })
    }

    onDemoMoveUp(setting) {
        this.moveDemo(setting, -1)
    }

    onDemoMoveDown(setting) {
        this.moveDemo(setting, 1)
    }

    onDemoDelete(setting) {
        let mergedSettings = this.state.mergedSettings
        mergedSettings.splice(setting.index, 1)
        if(mergedSettings[setting.index]) {
            mergedSettings.forEach(mergedSetting => {
                if(mergedSetting.index > setting.index)
                mergedSetting.index -= 1
            })
        }

        this.setState({
            mergedSettings: mergedSettings
        })
    }

    render() {
        const {classes} = this.props
        return (
            <>
                <div className={classes.actions}>
                    <Button variant="contained" color="primary"
                            className={classes.button}
                            onClick={() => this.onPublishClicked()}>
                        <PublishIcon className={classes.leftIcon}/>
                        PUBLISH
                    </Button>
                    <Button variant="contained" color="primary"
                            className={classes.button}
                            onClick={() => this.onIncreaseClicked()}>
                        <SyncProblemIcon className={classes.leftIcon}/>
                        INCREASE VERSION
                    </Button>
                </div>
                <Grid container className={classes.root}>
                    <Grid item xs={12}>
                        <Paper className={classes.root} spacing={16}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Index</TableCell>
                                        <TableCell>Creative</TableCell>
                                        <TableCell>Ad Type</TableCell>
                                        <TableCell>Publisher</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.mergedSettings.map(setting =>
                                        <TableRow key={setting.index}>
                                            <TableCell component="th" scope="row">
                                                {setting.index}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {setting.ad.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {setting.adType} ({setting.ad.mediaType})
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {setting.publisher.name} ({setting.publisher.lang})
                                            </TableCell>
                                            <TableCell component="th" scope="row">

                                                <Button variant="contained" color="primary"
                                                        className={classes.button}
                                                        onClick={() => this.onSettingClicked(setting)}>
                                                    <EditIcon className={classes.leftIcon}/>
                                                    EDIT
                                                </Button>

                                                <Button variant="outlined" color="primary"
                                                        className={classes.smallButton}
                                                        onClick={() => this.onDemoMoveUp(setting)}>
                                                    <KeyboardArrowUpIcon/>
                                                </Button>

                                                <Button variant="outlined" color="primary"
                                                        className={classes.smallButton}
                                                        onClick={() => this.onDemoMoveDown(setting)}>
                                                    <KeyboardArrowDownIcon/>
                                                </Button>

                                                <Button variant="outlined" color="primary"
                                                        className={classes.smallButton}
                                                        onClick={() => this.onDemoDelete(setting)}>
                                                    <DeleteIcon/>
                                                </Button>

                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                    <Grid>
                        <Button color="primary"
                                className={classes.button}
                                onClick={() => this.onNewDemoClick()}>
                            <AddIcon className={classes.leftIcon}/>
                            Add demo
                        </Button>
                    </Grid>
                </Grid>

                {this.state.editingItem && <EntityEditAddDialog
                    mode="edit"
                    onClose={(setting) => this.onDialogClosed(setting)}
                    setting={this.state.editingItem}/>}

                {this.state.addingDemo && <EntityEditAddDialog
                    mode="add"
                    addContinue
                    key={Date.now()}
                    onClose={(setting, shouldContinue) => {
                        this.onDialogClosed(setting)
                        if (shouldContinue) {
                            this.setState({
                                addingDemo: true
                            })
                        }
                    }}/>}

                {this.state.successMessage && <NotificationSnackbar
                    success={this.state.successMessage}
                    onClose={() => this.setState({successMessage: undefined})}
                />}

                {this.state.errorMessage && <NotificationSnackbar
                    error={this.state.errorMessage}
                    onClose={() => this.setState({errorMessage: undefined})}
                />}
            </>
        )
    }
}

export default withStyles(styles)(Entity);
