import React, { Component } from 'react';
import { database } from "../firebase/firebase"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import { Link } from "react-router-dom"
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import Button from "@material-ui/core/Button"
import NewEntityDialog from "./NewEntityDialog"
import getUrlParams from "../UrlParams"
import DeleteEntityDialog from "./DeleteEntityDialog"
import NotificationSnackbar from "../customComponent/NotificationSnackbar"

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: '15px; ',
        margin: '15px'
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit,
    }
});

class PartnerList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            addingEntity: false,
            deletingEntity: false,
            entities: []
        }
    }

    componentWillMount() {
        const partnerName = this.props.match.params.partnerName
        this.setState({
            selectedPartner: partnerName
        })

        database
            .ref(`v6/partners/${partnerName}/entities`)
            .on('value', (snapshot) => {
                const entities = []
                snapshot.forEach(function (childSnapshot) {
                    entities.push(childSnapshot.key)
                });

                this.setState({
                    entities: entities
                })
            }, error => {
                console.error(error)
            })
    }

    onNewEntityClick() {
        this.setState({
            addingEntity: true
        })
    }

    onNewEntityDialogClosed(entity) {
        this.setState({
            addingEntity: false
        })

        if (!entity) {
            // Cancelled
            return
        }

        const {partnerName } = getUrlParams(this.props.match)

        if(!partnerName) {
            alert('No partner name. 🤯')
            return
        }

        const entityName = entity.name.replace(/[^a-zA-Z0-9]+/g, "")

        database
            .ref(`v6/partners/${partnerName}/entities/${entityName}/`)
            .update(entity)
            .then(() => {
                this.setState({
                    successMessage: "New entity added"
                })
            })
            .catch(error => {
                console.error("Failed to add new entity", error)
                this.setState({
                    errorMessage: "Failed to add new entity, error:" + JSON.stringify(error)
                })
            })
    }

    onDeleteEntityClick() {
        this.setState({
            deletingEntity: true
        })
    }

    onDeleteEntityDialogClosed(entitKeyToDelete) {
        this.setState({
            deletingEntity: false
        })

        if(!entitKeyToDelete) {
            // canceled
            return
        }

        if(this.state.entities.filter(name =>name === entitKeyToDelete ).length === 0) {
            this.setState({
                errorMessage: `Entity ${entitKeyToDelete} doesn't exist. Maybe a typo?`
            })
        }

        const partnerName = this.props.match.params.partnerName
        database
            .ref(`v6/partners/${partnerName}/entities/${entitKeyToDelete}/`)
            .remove()
            .then(() => {
                this.setState({
                    successMessage: `Entity ${entitKeyToDelete} deleted`
                })
            })
            .catch(error => {
                console.error("Failed to delete entity", error)
                this.setState({
                    errorMessage: "Failed to delete entity, error:" + JSON.stringify(error)
                })
            })

    }

    render() {
        const {classes, match} = this.props
        const partnerDomain = match.params.partnerName
        return (
            <>
                <Grid container className={classes.root} spacing={16}>
                    <Grid item xs={12}>
                        <Grid container justify="center">

                            {this.state.entities.map(entity =>
                                <Grid item
                                      key={entity}
                                      xs={3}>
                                    <Link
                                        to={`${match.url}/entity/${entity}`}
                                        className="link">
                                        <Paper className={classes.paper}>
                                            {entity}
                                        </Paper>
                                    </Link>
                                </Grid>)}

                            <Grid item xs={12}>
                                <Button color="primary"
                                        className={classes.button}
                                        onClick={() => this.onNewEntityClick()}>
                                    <AddIcon className={classes.leftIcon}/>
                                    Add an entity
                                </Button>
                                <Button color="secondary"
                                        className={classes.button}
                                        onClick={() => this.onDeleteEntityClick()}>
                                    <DeleteIcon className={classes.leftIcon}/>
                                    Delete an entity
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.addingEntity && <NewEntityDialog
                    mode="add"
                    domain={partnerDomain}
                    onClose={(entity) => this.onNewEntityDialogClosed(entity)}/>}
                {this.state.deletingEntity && <DeleteEntityDialog
                    domain={partnerDomain}
                    onClose={(entityKey) => this.onDeleteEntityDialogClosed(entityKey)}/>}

                {this.state.successMessage && <NotificationSnackbar
                    success={this.state.successMessage}
                    onClose={() => this.setState({successMessage: undefined})}
                />}

                {this.state.errorMessage && <NotificationSnackbar
                    error={this.state.errorMessage}
                    onClose={() => this.setState({errorMessage: undefined})}
                />}
            </>
        )
    }
}

export default withStyles(styles)(PartnerList);
