import React, { Component } from 'react'
import firebase from 'firebase/app';
import 'firebase/auth';
import Error from "./customComponent/Error"
import LoaderMatchParent from "./customComponent/LoaderMatchParent"
import { Button } from "@material-ui/core"

class RequireAuth extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLogged: false,
            loginProcess: false,
            error: null
        }
    }

    componentWillMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    isLogged: true,
                    loginProcess: false
                })
            } else {
                this.setState({
                    isLogged: false,
                    loginProcess: false
                })
            }
        });

    }

    login() {
        const provider = new firebase.auth.GoogleAuthProvider();

        this.setState({
            loginProcess: true
        })

        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                this.setState({
                    isLogged: true,
                    loginProcess: false
                })
            })
            .catch((error) => {
                this.setState({
                    error: `${error.code} - ${error.message}`,
                    loginProcess: false
                })
            })
    }

    render() {
        if (this.state.isLogged) {
            return this.props.children
        }
        if (this.state.error) {
            return (
                <Error
                    error="Unable to sign you in."
                    errorDetail={this.state.error}
                />
            )
        }
        if (this.state.loginProcess) {
            return <LoaderMatchParent/>
        }
        return <Button onClick={() => this.login()}>Login</Button>
    }
}

export default RequireAuth