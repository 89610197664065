function Config() {
    switch (process.env.NODE_ENV) {
        case 'development':
            return {
                apiKey: "AIzaSyBrk62h_7FtONgHArQ3ZZBjzsCwPKQ2q3Q",
                authDomain: "teads-showcase-test.firebaseapp.com",
                databaseURL: "https://teads-showcase-test.firebaseio.com",
                projectId: "teads-showcase-test",
                storageBucket: "teads-showcase-test.appspot.com",
                messagingSenderId: "101967283029"
            }
        case 'production':
            return {
                apiKey: "AIzaSyAkMq93ck4FrkIx7_a_Q2e5WOqrRyPN1TE",
                authDomain: "teads-showcase.firebaseapp.com",
                databaseURL: "https://teads-showcase.firebaseio.com",
                projectId: "firebase-teads-showcase",
                storageBucket: "firebase-teads-showcase.appspot.com",
                messagingSenderId: "372970818645"
            }

        default:
            console.error('No demo for this NODE_ENV: ' + process.env.NODE_ENV)
            return {}
    }
}

export default Config
