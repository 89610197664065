import React, { Component } from 'react';
import { database } from "./firebase/firebase"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import { Link } from "react-router-dom"
import Typography from "@material-ui/core/Typography"

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        margin: '0 0 0 20px'
    },
    paper: {
        padding: '15px; ',
        margin: '15px',
        '&:hover': {
            background: "#eee"
        }
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
});

class PartnerList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            partners: []
        }
    }

    componentWillMount() {
        database
            .ref('v6/partners/')
            .on('value', (snapshot) => {
                const partners = []
                snapshot.forEach(function (childSnapshot) {
                    partners.push(childSnapshot.key)
                });

                this.setState({
                    partners: partners
                })
            }, error => {
                console.error(error)
            })
    }


    render() {
        const {classes} = this.props
        return (
            <Grid container className={classes.root} spacing={16}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom className={classes.title}>
                        Accounts
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center">

                        {this.state.partners.map(partner =>
                            <Grid item
                                  key={partner}
                                  xs={3}>
                                <Link
                                    to={`/partner/${partner}`}
                                    className="link">
                                    <Paper className={classes.paper}>
                                        {partner}
                                    </Paper>
                                </Link>
                            </Grid>)}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(PartnerList);
