import _ from "lodash"
import { AdTypeMapping } from "../dataTypeList"

export function mergeAdsPublishersSettings(dataObject) {

    const ads = dataObject.ads || []
    const publishers = dataObject.publishers || []
    const settings = dataObject.settings || []

    const mergedSettings = []

    settings.forEach(setting => {
        const mergedSetting = {}
        const maybeAd = ads.filter(ad => {
            // convert inRead Landscape to just landscape
            const typeResult = AdTypeMapping.filter(type => type.type === ad.type)[0]
            const adType = typeResult ? typeResult.adType : undefined

            return setting.ad === ad.name && setting.adMediaType === ad.mediaType && setting.adType === adType
        })
        const maybePublisher = publishers.filter(publisher => {
            if (!setting.publisher || !publisher.name) {
                return false
            }
            return setting.publisher.toLowerCase() === publisher.name.toLowerCase()
        })
        mergedSetting.ad = maybeAd[0] || {}
        mergedSetting.publisher = maybePublisher[0] || {
            name: "Washington Post",
            url: "https://mobile.teads.tv/showcase/data/publishers/washingtonpost.com.html",
            lang: "en"
        }
        mergedSetting.adType = setting.adType
        mergedSetting.index = setting.index
        mergedSetting.language = setting.language

        if (!setting.enable) {
            // Don't display disable demo in the UI
            return
        }

        mergedSettings.push(mergedSetting)
    })
    return mergedSettings.sort((a, b) => a.index - b.index)
}

export function unmergeAds(mergedSettings) {
    let ads = []
    let publishers = []
    const settings = []
    mergedSettings.forEach(setting => {

        if (!setting.ad.name) {
            throw new Error("Ad Name missing")
        }
        if (!setting.ad.url) {
            throw new Error("Ad Url missing")
        }
        if (!setting.publisher.name) {
            throw new Error("Publisher Name missing")
        }
        if (!setting.publisher.url) {
            throw new Error("Publisher URL missing")
        }

        ads.push({
            ...setting.ad
        })
        publishers.push({
            ...setting.publisher
        })
        settings.push({
            ad: setting.ad.name,
            adMediaType: setting.ad.mediaType,
            adRandom: false,
            adType: setting.adType,
            enable: true,
            endscreen: true,
            index: setting.index,
            language: setting.language ? setting.language : "en",
            publisher: setting.publisher.name
        })
    })

    // iOS Showcase app need at least 8 demo for the settings to be valid/applied /shrug
    if (settings.length < 8) {
        for (let i = settings.length; i < 8; i++) {
            settings.push({
                ad: settings[0].ad,
                adMediaType: settings[0].adMediaType,
                adRandom: false,
                adType: settings[0].adType,
                enable: false,
                index: i,
            })
        }
    }

    ads = _.uniqBy(ads, 'url')
    publishers = _.uniqBy(publishers, 'url')

    return {ads, publishers, settings}
}
