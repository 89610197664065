import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Switch from "react-router-dom/Switch"
import BrowserRouter from "react-router-dom/BrowserRouter"
import { Route } from "react-router-dom"

const render = Component => {
    return ReactDOM.render(
        <BrowserRouter>
            <Switch>
                <Route path="/" component={Component}/>
            </Switch>
        </BrowserRouter>,
        document.getElementById('root')
    )
}

render(App)

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default
        render(NextApp)
    })
}

