import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import withStyles from "@material-ui/core/styles/withStyles"
import MenuItem from "@material-ui/core/MenuItem"
import Grid from "@material-ui/core/Grid"
import { AdTypeMapping, MediaType } from "../dataTypeList"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import DialogEntityPublisher from "./DialogEntityPublisher"
import Typography from "@material-ui/core/Typography"

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    error: {
        color: "red"
    },
    space: {
        height: 40
    },
    switch: {
        marginTop: -10
    }
});

const defaultAdType = AdTypeMapping[0]
const defaultMediaType = MediaType[0]

class EntityEditAddDialog extends React.Component {


    constructor(props) {
        super(props)

        this.state = {
            existingPublisher: true,
            setting: props.setting ? props.setting : {}
        }

        const setting = this.state.setting
        if (setting) {
            this.state.setting.adType = setting.adType ? setting.adType : defaultAdType.adType
        } else {
            this.state.setting.adType = defaultAdType.adType
        }

        if (setting && setting.ad) {
            this.state.setting.ad.name = setting.ad.name ? setting.ad.name : ""
            this.state.setting.ad.url = setting.ad.url ? setting.ad.url : ""
            this.state.setting.ad.mediaType = setting.ad.mediaType ? setting.ad.mediaType : defaultMediaType
            this.state.setting.ad.type = setting.ad.type ? setting.ad.type : defaultAdType.type
        } else {
            this.state.setting.ad = {}
            this.state.setting.ad.name = ""
            this.state.setting.ad.mediaType = defaultMediaType
            this.state.setting.ad.type = defaultAdType.type
            this.state.setting.ad.url = ""
        }
        if (setting && setting.publisher) {
            this.state.setting.publisher.name = setting.publisher ? setting.publisher.name : ""
            this.state.setting.publisher.lang = setting.publisher ? setting.publisher.lang : "en"
            this.state.setting.publisher.url = setting.publisher ? setting.publisher.url : ""
        } else {
            this.state.setting.publisher = {}
            this.state.setting.publisher.name = ""
            this.state.setting.publisher.lang = "en"
            this.state.setting.publisher.url = ""
        }
    }

    onAdChange(value) {
        this.setState({
            setting: {
                ...this.state.setting,
                ad: {
                    ...this.state.setting.ad,
                    name: value
                }
            }
        })
    }

    onMediaTypeChange(value) {
        this.setState({
            setting: {
                ...this.state.setting,
                adType: defaultAdType.adType,
                ad: {
                    ...this.state.setting.ad,
                    mediaType: value,
                    type: defaultAdType.type
                }
            }
        })
    }

    onAdTypeChange(value) {
        const mapping = AdTypeMapping.filter(type => type.adType === value)[0]
        let mediaType = this.state.setting.ad.mediaType
        let type = mapping.type
        if (mapping.mediaTypeForced) {
            mediaType = mapping.mediaTypeForced
            type = mapping.type
        }

        this.setState({
            setting: {
                ...this.state.setting,
                adType: value,
                ad: {
                    ...this.state.setting.ad,
                    mediaType: mediaType,
                    type: type
                }
            }
        })
    }

    onClickThroughScreenshotBaseUrlChange(value) {
        this.setState({
            setting: {
                ...this.state.setting,
                ad: {
                    ...this.state.setting.ad,
                    siteUrlBase: value
                }
            }
        })
    }

    onClickThroughSiteUrlChange(value) {
        this.setState({
            setting: {
                ...this.state.setting,
                ad: {
                    ...this.state.setting.ad,
                    site_url: value
                }
            }
        })
    }

    onAdUrlChange(value) {
        this.setState({
            setting: {
                ...this.state.setting,
                ad: {
                    ...this.state.setting.ad,
                    url: value
                }
            }
        })
    }

    onPublisherChange(publisher) {
        this.setState({
            setting: {
                ...this.state.setting,
                publisher: publisher
            }
        })
    }

    getAdRenderVideo(setting, classes) {
        let site = ""

        if (setting.ad.siteUrlBase) {
            site = <TextField
                required
                id="siteurlnase-name"
                label="Advertiser ClickThrough Screenshot base url"
                helperText="Example: https://showcase.teads.tv/data/ads/americanexpress.com/americanexpress.com"
                value={setting.ad.siteUrlBase}
                onChange={(event) => this.onClickThroughScreenshotBaseUrlChange(event.target.value)}
            />
        } else {
            site = <TextField
                required
                id="siteclick-name"
                label="Advertiser ClickThrough file"
                value={setting.ad.site_url}
                onChange={(event) => this.onClickThroughSiteUrlChange(event.target.value)}/>
        }

        return <Grid container>
            <Grid item xs={12}>
                <FormControl required className={classes.formControl}>
                    {site}
                </FormControl>
            </Grid>
        </Grid>

    }

    onPublisherModeChange(value) {
        this.setState({
            existingPublisher: value
        })
    }

    handleClose(action, shouldContinue) {
        if (action === "cancel") {
            this.props.onClose(null)
        } else {
            const setting = this.state.setting

            if (!setting || !setting.ad || !setting.ad.name ||
                !setting.publisher || !setting.publisher.name || setting.publisher.name.length === 0 ||
                !setting.publisher.url) {
                this.setState({
                    error: "Fill all required fields plz (ad name, publisher)"
                })
                return
            }
            if (!setting.ad.url.endsWith(".zip") && setting.ad.mediaType === "vpaidOffline") {
                this.setState({
                    error: "vpaidOffline ads should be a .zip url"
                })
                return
            }

            if (!setting.ad.url.endsWith(".mp4") && setting.ad.mediaType === "video") {
                this.setState({
                    error: "video ads should be a .mp4 url"
                })
                return
            }

            setting.lang = setting.publisher.lang
            setting.ad.name = setting.ad.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
            setting.publisher.name = setting.publisher.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
            switch (setting.ad.mediaType) {
                case "vpaidOffline":
                    delete setting.ad.site_url
                    delete setting.ad.siteUrlBase
                    break;
                case "video":
                    switch (setting.adType) {
                        case "inRead Landscape":
                            setting.ad.width = 1280
                            setting.ad.height = 720
                            break
                        case "inRead Square":
                            setting.ad.width = 480
                            setting.ad.height = 480
                            break
                        case "inRead Vertical":
                            setting.ad.height = 1280
                            setting.ad.width = 720
                            break
                        default:
                            // nada
                            break
                    }
                    break
                default:
                    console.error("Error: " + setting.ad.mediaType + " not managed in dialog on save")
                    break
            }
            this.props.onClose(this.state.setting, shouldContinue)
        }
    }

    render() {
        const {mode, classes, addContinue} = this.props
        const {setting} = this.state

        let adType = setting.adType
        let ad = setting.ad.name
        let mediaType = setting.ad.mediaType
        let adUrl = setting.ad.url

        if (!adUrl && mediaType === "vpaidOffline") {
            adUrl = "https://showcase.teads.tv/data/ads/"
        }

        return (
            <Dialog
                open={true}
                fullWidth={true}
                maxWidth="md"
                onClose={() => this.props.onClose()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle
                    id="form-dialog-title">{mode === "edit" ? `Editing demo #${setting.index}` : "Adding a new demo"}</DialogTitle>
                <DialogContent>

                    <Grid container className={classes.root} spacing={16}>
                        <Grid item xs={6}>
                            <FormControl required className={classes.formControl}>
                                <TextField
                                    required
                                    id="ad-name"
                                    label="Ad Name"
                                    value={ad}
                                    onChange={(event) => this.onAdChange(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl required className={classes.formControl}>
                                <InputLabel htmlFor="mediatype-required">Media Type</InputLabel>
                                <Select
                                    value={mediaType}
                                    onChange={(event) => this.onMediaTypeChange(event.target.value)}
                                    name="Media Type"
                                    inputProps={{
                                        id: 'mediatype-required',
                                    }}
                                >
                                    {MediaType.map(type => <MenuItem value={type} key={type}>{type}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl required className={classes.formControl}>
                                <InputLabel htmlFor="adtype-required">Ad Type</InputLabel>
                                <Select
                                    value={adType}
                                    onChange={(event) => this.onAdTypeChange(event.target.value)}
                                    name="Ad Type"
                                    inputProps={{
                                        id: 'adtype-required',
                                    }}
                                >
                                    {AdTypeMapping.map(type => <MenuItem value={type.adType}
                                                                         key={type.adType}>{type.adType}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        {mediaType === "video" && <Grid item xs={12}>
                            {this.getAdRenderVideo(setting, classes)}
                        </Grid>}

                        <Grid item xs={12}>
                            <FormControl required className={classes.formControl}>
                                <TextField
                                    required
                                    id="creativeurl"
                                    label="Ad Url"
                                    helperText={mediaType === "video" ? ".mp4 video url" : ".zip generate by the export tool"}
                                    value={adUrl}
                                    onChange={(event) => this.onAdUrlChange(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} className={classes.space}> </Grid>


                        <Grid item xs={3}>
                            <Typography variant="h6">
                                Publisher
                            </Typography>
                        </Grid>

                        <Grid item xs={9}>
                            <FormControlLabel
                                className={classes.switch}
                                control={
                                    <Switch
                                        onChange={(event) => this.onPublisherModeChange(event.target.checked)}
                                        checked={this.state.existingPublisher}
                                    />
                                }
                                label="Existing Publisher"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DialogEntityPublisher
                                value={this.state.setting.publisher}
                                onChange={publisher => this.onPublisherChange(publisher)}
                                autoComplete={this.state.existingPublisher}
                            />
                        </Grid>

                        {this.state.error && <Grid item xs={12} className={classes.error}>
                            Error: {this.state.error}
                        </Grid>}

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleClose("cancel")}>
                        Cancel
                    </Button>
                    <Button onClick={() => this.handleClose()} color="primary">
                        {mode === "edit" ? `Save` : "Add demo"}
                    </Button>
                    {
                        addContinue && <Button onClick={() => this.handleClose(undefined, true)} color="primary">
                            Add & continue
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(EntityEditAddDialog)
